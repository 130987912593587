// File: assets/clubData/clubProfilesIndex.js

import { SF_CLUB_PROFILES } from './clubProfile/sanfrancisco/sfClubProfiles.js';
import { EAST_BAY_CLUB_PROFILES } from './clubProfile/eastbay/eastBayClubProfiles.js';
import { SANTA_BARBARA_CLUB_PROFILES } from './clubProfile/santabarbara/sbClubProfiles.js';
import { SACRAMENTO_CLUB_PROFILES } from './clubProfile/sacramento/sacClubProfiles.js';
import { SAN_DIEGO_CLUB_PROFILES } from './clubProfile/sandiego/sdClubProfiles.js';
import { SAN_JOSE_CLUB_PROFILES } from './clubProfile/sanjose/sjClubProfiles.js';
import { SONOMA_CLUB_PROFILES } from './clubProfile/sonoma/sonomaClubProfiles.js';
import { NAPA_CLUB_PROFILES } from './clubProfile/napa/napaClubProfile.js';
import { MARIN_CLUB_PROFILES } from './clubProfile/marin/marinClubProfile.js';
import { PALO_ALTO_CLUB_PROFILES } from './clubProfile/paloalto/paloaltoClubProfile.js';

export const ALL_CLUB_PROFILES = {
  ...SF_CLUB_PROFILES,
  ...EAST_BAY_CLUB_PROFILES,
  ...SANTA_BARBARA_CLUB_PROFILES,
  ...SACRAMENTO_CLUB_PROFILES,
  ...SAN_DIEGO_CLUB_PROFILES,
  ...SAN_JOSE_CLUB_PROFILES,
  ...SONOMA_CLUB_PROFILES,
  ...NAPA_CLUB_PROFILES,
  ...MARIN_CLUB_PROFILES,
  ...PALO_ALTO_CLUB_PROFILES
};
