export const EAST_BAY_RUN_CLUB_DATA = [

  {
    id: "womens-eastbay",
    title: "Women's Run Club East Bay",
    subtitle: "East Bay, Bay Area",
    description: "A new running community for women.",
    videoSrc: require('../../womens.mp4'),
    img: require('../../images/eastbay/wrc.png'),
    categories: ["Female-only", "Free", "Evening"],
    day: ["Tuesday", "Thursday"],
    daysOfWeek: ["Tuesday", "Thursday"],
    city: "East Bay", // Added city
  },

  {
    id: "coffee-run-collective",
    title: "Coffee Run Collective",
    subtitle: "East Bay, Bay Area",
    description: "Runs and fresh brews.",
    videoSrc: require('../../coffeecollective.mp4'),
    img: require('../../images/eastbay/coffecollective1.jpeg'),
    // categories: ["Free", "Evening", ],
    // day: ["Tuesday", "Thursday"],
    // daysOfWeek: ["Tuesday", "Thursday"],
    city: "East Bay", // Added city
  },

  {
    id: "town-strides",
    title: "Town Strides",
    subtitle: "Oakland, CA",
    description: "Oakland-based run club focused on community.",
    img: require('../../images/eastbay/townstrides.png'), // Add manually
    categories: ["Social", "Free", "Morning"],
    day: ["Sunday"],
    daysOfWeek: ["Sunday"],
    city: "East Bay",
  }
  


  ];

