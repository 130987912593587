export const PALO_ALTO_CLUB_DATA = [

  {
    id: "palo-alto-run-club",
    title: "Palo Alto Run Club (PARC)",
    subtitle: "Palo Alto, CA",
    description: "A long-standing running club for all paces and abilities.",
    img: require('../../images/paloalto/paloalto.png'), // Add manually
    categories: ["Social", "Competitive", "Morning"],
    day: ["Saturday"],
    daysOfWeek: ["Saturday"],
    city: "Palo Alto",
  }
  



  ];

