export const SANTA_BARBARA_RUN_CLUB_DATA = [
  
    {
      id: "sloppy-seal",
      title: "Sloppy Seal Run Club",
      subtitle: "",
      isVerified: true,
      description: "3 rad miles and then beer!",
      img: require('../../images/santabarbara/sloppyseal.png'),
      categories: ["Post-Run Socials", "Free"],
      day: ["Thursday"],
      daysOfWeek: ["Thursday"],
      city: "Santa Barbara", // Added city
    },

    {
      id: "santa-barbara-run-club",
      title: "Santa Barbara Run Club",
      subtitle: "",
      isVerified: true,
      description: "A place for ANY pace!",
      img: require('../../images/santabarbara/sb.png'),
      categories: ["Post-Run Socials", "Free"],
      day: ["Friday"],
      daysOfWeek: ["Friday"],
      city: "Santa Barbara", // Added city
    },

    {
      id: "the-sb-club",
      title: "The Santa Barbara Run Club",
      subtitle: "",
      isVerified: true,
      description: "They run the funk",
      img: require('../../images/santabarbara/tsb.png'),
      categories: ["Post-Run Socials", "Free"],
      day: ["Wednesday", "Saturday"],
      daysOfWeek: ["Wednesday", "Saturday" ],
      city: "Santa Barbara", // Added city
    },

    {
      id: "trailtuesday",
      title: "Trail Tuesday",
      subtitle: "",
      isVerified: true,
      description: "A fun and welcoming trail running club in Santa Barbara!",
      img: require('../../images/santabarbara/trailtuesday.png'),
      categories: ["Trail Running", "Free", "Weekly"],
      day: ["Tuesday"],
      daysOfWeek: ["Tuesday"],
      city: "Santa Barbara",
    },

    {
      id: "sb-running-co-saturday-shred",
      title: "SB Running Co.",
      subtitle: "Trail Runners of Santa Barbara",
      isVerified: true,
      description: "A friendly and adventurous trail running club in Santa Barbara.",
      img: require('../../images/santabarbara/sb_running_co.png'),
      categories: ["Social", "Free", "Morning"], // Adjust categories manually if needed
      day: ["Saturday"],
      daysOfWeek: ["Saturday"],
      city: "Santa Barbara",
    }
    
    
]