import runfreerc from '../../images/sanjose/rf.png';

export const SAN_JOSE_CLUB_PROFILES = {

    "runfreerc": {
  id: "run-free-run-club",
  title: "Run Free Run Club (RFRC)",
  images: [runfreerc], // Add images manually
  isVerified: true,
  badges: ["free", "social", "morning"],
  whatToExpect: "Run Free Run Club is a welcoming community designed to unite runners of all levels. Whether you’re chasing a PR or just looking to enjoy a casual run with a supportive group, RFRC offers a fun and energetic environment. Each run is a chance to connect with fellow runners and enjoy the beauty of the South Bay.",
  howToJoin: "Just show up at any of their weekly runs. No registration required—just bring your running shoes and energy!",
  description:
    "Run Free Run Club (RFRC) is a San Jose-based running community that celebrates the freedom of the open road. They meet every Saturday morning for a social run, inviting runners of all levels to come together, explore local trails, and build meaningful connections.",
  subtitle: "San Jose, CA",
  whyWeLikeIt:
    "RFRC creates a vibrant and inclusive space for runners to enjoy the freedom of movement. The club balances structured routes with a relaxed and social vibe, making it a great option for runners looking to build consistency while meeting new people.",
  socialInfo: [
    "Post-run socials",
    "All levels and paces welcome",
    "San Jose-based running community",
    "Scenic South Bay routes"
  ],
  contactInfo: [
    {
      name: "Instagram",
      url: "https://www.instagram.com/runfree.rc/?hl=en",
      platform: "instagram",
    },
    {
      name: "Strava",
      url: "https://www.strava.com/clubs/runfreerc?share_sig=1E0BDA981715825827&fbclid=PAZXh0bgNhZW0CMTEAAaYAU3BPKM3xNZmy1OVRDyrXDLzhByXODqpYZCisNDB0JyPHZqO3gHVvkCg_aem_CPHW__JvpvnJebvcCGcMvA&_branch_match_id=1295599200301916651&_branch_referrer=H4sIAAAAAAAAAwXB2wqCMBgA4LfpUg2hi0BibpGYJ6KD7WZsazrxNH9lZRc9e9%2Bnl8XMe9edF%2BCWO9wYp2uG1jWl3CVHGEgsDpWQXfMKCkRL7Yk60%2FTh4fR6RIg%2F0c0Pi3PqfzLar9v8fiErlCT56nAtczKZJ8XNnJHQi9ciolPu19HdtrhmXPUMF9GDsdgaO8RKWIlPMrVo8wNVKYBmqJmA8T0rCLCGsVd%2Fbi8Bsq0AAAA%3D",
      platform: "strava",
    },
  ],
  meetPoint: {
    details: ["Meet at Guadalupe Trail", "Post-run social at Qargo Coffee"],
    link: {
      text: "Guadalupe Trail, San Jose",
      url: "https://maps.app.goo.gl/xyz", // Replace with actual link if available
    },
  },
  runDays: [
    {
      day: "Saturday",
      times: ["9:00 AM"],
      distance: "3 or 4 miles",
      location: "Location varies (details on Strava)",
      googleMapLink: ""
    }
  ],
  joinLink: "",
  instagramEmbed: `<blockquote class="instagram-media" data-instgrm-permalink="https://www.instagram.com/runfree.rc/?hl=en" data-instgrm-version="14" style=" background:#FFF; border-radius:px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:540px; min-width:326px; padding:0; width:100%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);"><div style="padding:16px; border:0"> <a href="https://www.instagram.com/runfree.rc/?hl=en" style=" background:#FFFFFF; line-height:0; padding:0 0; text-align:center; text-decoration:none; width:100%;" target="_blank"> <div style=" display: flex; flex-direction: row; align-items: center;"> <div style="background-color: #F4F4F4; border-radius: 50%; flex-grow: 0; height: 40px; margin-right: 14px; width: 40px;"></div> <div style="display: flex; flex-direction: column; flex-grow: 1; justify-content: center;"> <div style=" background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; margin-bottom: 6px; width: 100px;"></div> <div style=" background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; width: 60px;"></div></div></div><div style="padding: 19% 0;"></div> <div style="display:block; height:50px; margin:0 auto 12px; width:50px;"></div></a></div></blockquote>`,
    }

   
        }


