import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './CitySelector.css'; // Use updated styles to match Resy look
import { CITIES } from '../../assets/runClubDataIndex';

import { IoMdArrowDropdown } from "react-icons/io"; // Importing an arrow icon from react-icons

const CitySelector = ({ selectedCity, setSelectedCity }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setIsOpen(false);
    // Format the city name for the URL (e.g., "San Francisco" -> "san-francisco")
    const formattedCity = city.toLowerCase().replace(/\s+/g, '-');
    // Update the navigation path to match your route in App.jsx
    navigate(`/runclubs/${formattedCity}`);
  };

  // Close the dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="city-selector-dropdown-container" ref={dropdownRef}>
      {/* Text to toggle the dropdown */}
      <div
        className="city-selector-text"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span className="city-name">
          {selectedCity || "Select a City"}
        </span>
        <IoMdArrowDropdown className="dropdown-icon" />
      </div>

      {/* Dropdown menu */}
      {isOpen && (
        <div className="city-selector-dropdown">
          <div className="city-list">
            {CITIES.sort().map((city) => (  // Sort cities alphabetically
              <div
                key={city}
                className={`city-option ${city === selectedCity ? 'selected' : ''}`}
                onClick={() => handleCitySelect(city)}
              >
                {city}  {/* This will now show the actual city name from the data */}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CitySelector;
