export const SAN_DIEGO_CLUB_DATA = [

    {
        id: "sundayrunday",
        title: "Sunday Runday SD Run Club",
        subtitle: "San Diego",
        isVerified: true,
        description: "A San Diego running club that mixes Sunday runs with yoga and social vibes!",
        img: require('../../images/sandiego/sundayrunday.png'),
        categories: ["Social", "Free", "Morning"],
        day: ["Sunday"],
        daysOfWeek: ["Sunday"],
        city: "San Diego",
      },

      {
        id: "pbrc",
        title: "Pacific Beach Run Club",
        subtitle: "San Diego",
        isVerified: true,
        description: "San Diego’s scenic beachside running club",
        img: require('../../images/sandiego/pbrc.png'),
        categories: ["Social", "Free", "Morning", "Evening"],
        day: ["Wednesday", "Saturday"],
        daysOfWeek: ["Wednesday", "Saturday"],
        city: "San Diego",
      },

      {
        id: "obrunclub",
        title: "Ocean Beach Run Club",
        subtitle: "San Diego",
        isVerified: true,
        description: "San Diego’s laid-back beachside running club",
        img: require('../../images/sandiego/obrc.png'),
        categories: ["Social", "Free", "Morning", "Evening"],
        day: ["Tuesday", "Thursday"],
        daysOfWeek: ["Tuesday", "Thursday"],
        city: "San Diego",
      },

      {
        id: "runnorthcounty",
        title: "Run North County",
        subtitle: "San Diego",
        isVerified: true,
        description: "Connecting runners across North County San Diego",
        img: require('../../images/sandiego/rnc.png'),
        categories: ["Social", "Free", "Morning", "Evening"],
        day: ["Saturday", "Tuesday"],
        daysOfWeek: ["Saturday", "Tuesday"],
        city: "San Diego",
      },

      {
        id: "ultrabudsrunclub",
        title: "Ultrabuds Run Club",
        subtitle: "San Diego",
        isVerified: true,
        description: "Ultra runners and trail lovers with a social twist.",
        img: require('../../images/sandiego/ultrabuds.png'),
        categories: ["Social", "Free"],
        day: [],
        daysOfWeek: [],
        city: "San Diego",
      },

      {
        id: "tuesdayswaterfrontrun",
        title: "Tuesdays Waterfront Run",
        subtitle: "San Diego",
        isVerified: true,
        description: "A scenic 3-5 mile waterfront run every Tuesday at 6 PM.",
        img: require('../../images/sandiego/twr.png'),
        categories: ["Social", "Free", "Evening"],
        day: ["Tuesday"],
        daysOfWeek: ["Tuesday"],
        city: "San Diego",
      },
      


      
      
      
      
    
      
]
