import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import { ALL_RUN_CLUB_DATA } from "../assets/runClubDataIndex.js";
import { ALL_CLUB_PROFILES } from "../assets/clubProfilesIndex.js";
import "./ClubProfile.css";
import BackButton from './backbutton/BackButton';
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LanguageIcon from "@mui/icons-material/Language";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStrava } from "@fortawesome/free-brands-svg-icons";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import GroupIcon from "@mui/icons-material/Group";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PersonIcon from "@mui/icons-material/Person";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import InfoIcon from "@mui/icons-material/Info";
import ListIcon from "@mui/icons-material/List";
import RunDetailsSharingComponent from './RunDetailsSharingComponent';
import { UserPlus } from 'lucide-react';
import { Helmet } from 'react-helmet';
import ClubMap from './ClubMap';
import ClubMapRun from './ClubMapRun';
import ShareClub from "./ShareClub";
import VerifiedBadge from "./VerifiedBadge";
import Host from "./Host";
import FaceIcon from "@mui/icons-material/Face";
import CoffeeIcon from "@mui/icons-material/Coffee";
import Badge from './badge.jsx';

function ClubProfile() {
  const { city, id } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  // Get basic club data from ALL_RUN_CLUB_DATA
  const basicClubData = ALL_RUN_CLUB_DATA.find(club => club.id === id);
  // Get extended club profile data from ALL_CLUB_PROFILES
  const extendedClubData = ALL_CLUB_PROFILES[id];
  
  // Merge the data, with extended data taking precedence
  const club = {
    ...basicClubData,
    ...extendedClubData
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (club) {
      ReactGA.event({
        category: "User",
        action: "Selected Club Profile",
        label: club.title,
      });
    }

    const script = document.createElement('script');
    script.src = '//www.instagram.com/embed.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [club]);

  useEffect(() => {
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
    setIsLoading(false);
  }, []);

  if (!club) {
    return <h2>Club not found</h2>;
  }

  const socialInfoIcons = [
    <CoffeeIcon className="club-icon" />,
    <GroupIcon className="club-icon" />,
    <PersonIcon className="club-icon" />,
    <DirectionsRunIcon className="club-icon" />,
    <FaceIcon className="club-icon" />,
  ];

  const contactIcons = {
    instagram: <InstagramIcon className="club-icon" />,
    facebook: <FacebookIcon className="club-icon" />,
    whatsapp: <WhatsAppIcon className="club-icon" />,
    website: <LanguageIcon className="club-icon" />,
    strava: <FontAwesomeIcon icon={faStrava} className="club-icon" />,
  };

  const runInfoIcons = {
    day: <CalendarTodayIcon className="run-icon" />,
    time: <AccessTimeIcon className="run-icon" />,
    distance: <DirectionsRunIcon className="run-icon" />,
    location: <LocationOnIcon className="run-icon" />,
    workoutType: <ListIcon className="run-icon" />,
    info: <InfoIcon className="run-icon" />
  };

  const handleJoinClubClick = () => {
    ReactGA.event({
      category: "User",
      action: "Join Club",
      label: club.title,
      value: id,
    });
  };

  const handleViewRouteClick = (routeLink) => {
    window.open(routeLink, '_blank');
  };

  return (
    <div className="club-profile">
      <Helmet>
        <title>{club.title} | Club Profile</title>
        <meta name="description" content={club.description} />
      </Helmet>
      <div className="club-header">
        <BackButton />
      </div>
      <ShareClub
        clubUrl={`https://clubsta.co/club/${city.toLowerCase()}/${id}`}
        clubTitle={club.title}
      />
      <h1>{club.title}</h1>
      <div className="club-hero">
        <div className="club-images">
          {Array.isArray(club.images) && club.images.length > 0 ? (
            club.images.slice(0, 3).map((image, index) => (
              <img key={index} src={image} alt={`${club.title} ${index + 1}`} className="club-image" />
            ))
          ) : (
            club.mainImage && <img src={club.mainImage} alt={club.title} className="club-image" />
          )}
        </div>
      </div>

      <p className="club-location">{club.subtitle}</p>



      <div className="badges">
        {club.badges && (
          Array.isArray(club.badges)
            ? club.badges.map((badgeType) => (
              <Badge key={badgeType} type={badgeType} />
            ))
            : <Badge type={club.badges} />
        )}
      </div>

      <hr style={{ border: '1px solid #ccc', marginTop: '30px' }} />

      <Host clubId={id} />

      <div className="club-content">
        <main className="club-main">
        {club.joinLink && (
            <a
              href={club.joinLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}
            >
              <button className="join-button" onClick={handleJoinClubClick}>
                <UserPlus size={18} />
                <span>Join Club</span>
              </button>
            </a>
          )}
          {club.description && (
            <section className="club-about">
              <h2>About</h2>
              <p>{club.description}</p>
            </section>
          )}

          {club.whatToExpect && (
            <section className="club-what-to-expect">
              <h2>What To Expect</h2>
              <p>{club.whatToExpect}</p>
            </section>
          )}

          {club.whyWeLikeIt && (
            <section className="club-why-we-like-it">
              <h2>Why We Like It</h2>
              <p>{club.whyWeLikeIt}</p>
            </section>
          )}

          {club.howToJoin && (
            <section className="club-how-to-join">
              <h2>How To Join</h2>
              <p>{club.howToJoin}</p>
            </section>
          )}

          {club.socialInfo && club.socialInfo.length > 0 && (
            <section className="club-details">
              <h2>Club Details</h2>
              <ul>
                {club.socialInfo.map((text, index) => (
                  <li key={index} className="club-detail-item">
                    {socialInfoIcons[index]}
                    <span className="detail-label">{text}</span>
                  </li>
                ))}
              </ul>
            </section>
          )}

          {club.contactInfo && club.contactInfo.some(item => item.url) && (
            <section className="club-details">
              <h2>Links</h2>
              <ul>
                {club.contactInfo.filter(item => item.url).map((item, index) => (
                  <li key={index} className="contact-detail-item">
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      {contactIcons[item.platform]}
                      <span className="detail-value">
                        {item.platform.charAt(0).toUpperCase() + item.platform.slice(1)}
                      </span>
                    </a>
                  </li>
                ))}
              </ul>
            </section>
          )}

          {/* Upcoming Events Section */}
          {club.events && club.events.length > 0 && (
            <section className="club-upcoming-events">
              <h2>Upcoming Events</h2>
              {club.events.map((event, index) => (
                <div key={index} className="event-details">
                  <p><strong>Event:</strong> {event.name}</p>
                  <p><strong>Date:</strong> {event.date}</p>
                  <p><strong>Time:</strong> {event.time}</p>
                  {event.link && (
                    <a
                      href={event.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="event-link"
                    >
                      {event.linkText || "Register for the Event"}
                    </a>
                  )}
                </div>
              ))}
            </section>
          )}
        </main>

        <aside className="club-sidebar">

          {club.runDays && club.runDays.length > 0 && (
            <section className="run-details">
              <h2>Run Details</h2>
              <RunDetailsSharingComponent
                clubTitle={club.title}
                runDays={club.runDays}
                clubId={club.id}
              />
              <div className="run-days-container">
                {club.runDays.map((runDay, index) => (
                  <div key={index} className="run-day-card">
                    <div className="run-day-header">
                      {runInfoIcons.day}
                      <span>{runDay.day}</span>
                    </div>
                    <div className="run-day-info">
                      {runDay.times && (
                        <div className="run-info-item">
                          {runInfoIcons.time}
                          <span>{Array.isArray(runDay.times) ? runDay.times.join(", ") : runDay.times}</span>
                        </div>
                      )}
                      {runDay.distance && (
                        <div className="run-info-item">
                          {runInfoIcons.distance}
                          <span>{runDay.distance}</span>
                        </div>
                      )}
                      {runDay.location && (
                        <div className="run-info-item">
                          {runInfoIcons.location}
                          <span>{runDay.location}</span>
                        </div>
                      )}
                      {runDay.workoutType && (
                        <div className="run-info-item">
                          {runInfoIcons.workoutType}
                          <span>{runDay.workoutType}</span>
                        </div>
                      )}
                      {runDay.info && (
                        <div className="run-info-item">
                          {runInfoIcons.info}
                          <span>{runDay.info}</span>
                        </div>
                      )}
                    </div>
                    <div className="run-map">
                      {runDay.googleMapLink && <ClubMapRun googleMapLink={runDay.googleMapLink} />}
                    </div>
                  </div>
                ))}
              </div>
            </section>
          )}
        </aside>
      </div>

      <div className="feeds-and-reviews">
        {club.instagramEmbed && (
          <section className="club-feeds">
            <h2>Feed</h2>
            <div className="feed-container">
              <div
                className="instagram-feed"
                dangerouslySetInnerHTML={{ __html: club.instagramEmbed }}
              />
            </div>
          </section>
        )}

        {club.googleMapLink && (
          <section className="club-map">
            <h2>Neighbourhood</h2>
            <ClubMap googleMapLink={club.googleMapLink} />
          </section>
        )}
      </div>
    </div>
  );
}

export default ClubProfile;
