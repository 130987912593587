import trailthurs from "../../images/marin/trailthurs.jpg";



export const MARIN_CLUB_PROFILES = {

"trail-thursdays": {
  id: "trail-thursdays",
  title: "Trail Thursdays",
  images: [trailthurs], // Add images manually
  isVerified: true,
  badges: ["morning", "social", "free"],
  whatToExpect: "Trail Thursdays is an early morning trail-running crew that meets at Headlands Parking Lot before taking on scenic sunrise runs through Marin’s stunning trails. Expect a supportive group, breathtaking views, and an energizing start to your day.",
  howToJoin: "Simply show up at the meeting point before 5:50 AM, ready to run! No sign-ups or fees required.",
  description:
    "Trail Thursdays is a community-driven trail-running group in Marin that embraces early starts and unforgettable sunrises. Runners leave promptly at 6 AM, covering a mix of terrain before wrapping up around 7:40 AM.",
  subtitle: "Marin, CA",
  whyWeLikeIt:
    "If you love sunrise views and trail adventures, Trail Thursdays is the perfect way to kick off your morning. The community is welcoming, and every run feels like an escape into nature.",
  socialInfo: [
    "Scenic sunrise runs",
    "Trail-focused group",
    "All paces welcome",
    "Social post-run hangs"
  ],
  contactInfo: [
    {
      name: "Instagram",
      url: "https://www.instagram.com/trailthursday/",
      platform: "instagram",
    }
  ],
  meetPoint: {
    details: ["Meet at 5:50 AM, leave at 6:00 AM", "Expect ~1 hr 40 min runs"],
    link: {
      text: "Headlands Parking Lot, Marin",
      url: "https://maps.app.goo.gl/zw8NYpHg1SDoNCo39?g_st=com.google.maps.preview.copy",
    },
  },
  runDays: [
    {
      day: "Thursday",
      times: ["6:00 AM"],
      distance: "Varies",
      location: "Headlands Parking Lot, Marin",
      googleMapLink: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9229.21155525834!2d-122.4835576277172!3d37.82965654601074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x808584289574ecd3%3A0x9718db3674bd6c47!2sParking%20lot%2C%20Conzelman%20Rd%2C%20Mill%20Valley%2C%20CA%2094941!5e0!3m2!1sen!2sus!4v1739845525013!5m2!1sen!2sus"
    }
  ],
  joinLink: "",
  instagramEmbed: `<blockquote class="instagram-media" data-instgrm-permalink="https://www.instagram.com/trailthursday/" data-instgrm-version="14" style=" background:#FFF; border-radius:px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:540px; min-width:326px; padding:0; width:100%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);"><div style="padding:16px; border:0"> <a href="https://www.instagram.com/trailthursday/" style=" background:#FFFFFF; line-height:0; padding:0 0; text-align:center; text-decoration:none; width:100%;" target="_blank"> <div style=" display: flex; flex-direction: row; align-items: center;"> <div style="background-color: #F4F4F4; border-radius: 50%; flex-grow: 0; height: 40px; margin-right: 14px; width: 40px;"></div> <div style="display: flex; flex-direction: column; flex-grow: 1; justify-content: center;"> <div style=" background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; margin-bottom: 6px; width: 100px;"></div> <div style=" background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; width: 60px;"></div></div></div><div style="padding: 19% 0;"></div> <div style="display:block; height:50px; margin:0 auto 12px; width:50px;"></div></a></div></blockquote>`,
}


};