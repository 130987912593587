import React from 'react';
import './ClaimClub.css';
import BackButton from './backbutton/BackButton';

const ClaimClub = () => {
  return (
    <div className="claim-container">
      <BackButton/>
      <header className="claim-header">
        <h1 className="claim-title">Claim Your Club</h1>
        <p className="claim-subtitle">Update the details on your club page</p>
      </header>

      <main className="claim-content">
        <section className="claim-section">
          <h3 className="section-title">Why Claim Your Club?</h3>
          <div className="section-content">
            <p>By claiming your club, you can manage the information that appears on clubsta, ensuring that runners have the most accurate and up-to-date details about your club.</p>
            <p>This is a great way to attract new members and keep your current members informed!</p>
          </div>
        </section>

        <section className="claim-section">
          <h3 className="section-title">What You Can Do</h3>
          <div className="section-content">
            <p>Once you've claimed your club, you'll be able to:</p>
            <ul className="claim-list">
              <li>Update run details like meet times, distances and pace</li>
              <li>Update club details</li>
              <li>Update club images</li>
              <li>Update club descriptions</li>
              <li>Update club location</li>
              <li>Update club contact details</li>
            </ul>
          </div>
        </section>

        <section className="claim-section">
          <h3 className="section-title">How to Claim Your Club</h3>
          <div className="section-content">
            <p>To claim your club, simply send an email to <a href="mailto:luke.furnellfu@clubsta.co" className="claim-link">luke.furnell@clubsta.com</a> with your club's details and your role within the club. Our team will verify that you are the club owner and then grant you access to manage the club.</p>
          </div>
        </section>

        <section className="claim-section">
          <h3 className="section-title">Need Help?</h3>
          <div className="section-content">
            <p>If you have any questions or need assistance with claiming your club, feel free to reach out to us at <a href="mailto:luke.furnell@clubsta.co" className="claim-link">luke.furnell@clubsta.co</a>.</p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default ClaimClub;