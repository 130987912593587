export const MARIN_RUN_CLUB_DATA = [

    {
        id: "trail-thursdays",
        title: "Trail Thursdays",
        subtitle: "Marin, CA",
        description: "An early morning trail-running crew embracing scenic Marin sunrises.",
        img: require('../../images/marin/trailthurs.jpg'), // Add manually
        categories: ["Morning", "Social", "Free"],
        day: ["Thursday"],
        daysOfWeek: ["Thursday"],
        city: "Marin",
      }
      


]