import React, { useEffect } from 'react';
import './Help.css';
import BackButton from './backbutton/BackButton';

const Help = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="help-container">
      <BackButton />
      <header className="help-header">
        <h1 className="help-title">Help</h1>
        <p className="help-subtitle">How can we assist you in finding and joining a running club?</p>
      </header>

      <main className="help-content">
        <section className="help-section">
          <h3 className="section-title">Getting Started</h3>
          <div className="section-content">
            <p>Welcome to Clubsta! Our platform is designed to help you find, join, and participate in running clubs in San Francisco. Whether you're new to running or an experienced marathoner, we have a community for you. Here's how you can get started:</p>
            <ul className="help-list">
              <li>Use our search feature to find clubs near you.</li>
              <li>Explore club profiles to learn more about their routes, pace, and social activities.</li>
              <li>Join a club directly through the provided links.</li>
            </ul>
          </div>
        </section>

        <section className="help-section">
          <h3 className="section-title">Frequently Asked Questions (FAQ)</h3>
          <div className="section-content">
            <p>Here are some common questions and answers that might help you:</p>
            <ul className="help-list">
              <li><strong>How do I find a running club near me?</strong> Use the search bar at the top of the page to search by location, day, or type of run.</li>
              <li><strong>Can I join a club without signing up?</strong> Most clubs allow you to join without any formal sign-up. Just show up at the meeting point!</li>
              <li><strong>How can I contact a club organizer?</strong> Each club profile includes contact details like Instagram, WhatsApp, or direct links to their website.</li>
            </ul>
          </div>
        </section>

        <section className="help-section">
          <h3 className="section-title">Support & Contact</h3>
          <div className="section-content">
            <p>If you need further assistance, we're here to help! Reach out to us at <a href="mailto:luke.furnell@clubsta.co" className="help-link">luke.furnell@clubsta.co</a> with any questions or concerns.</p>
            <p>You can also follow us on Instagram for the latest updates and tips.</p>
          </div>
        </section>

        <section className="help-section">
          <h3 className="section-title">Feedback</h3>
          <div className="section-content">
            <p>Your feedback is important to us. If you have suggestions on how we can improve our platform, please let us know at <a href="mailto:luke.furnell@clubsta.co" className="help-link">luke.furnell@clubsta.co</a>.</p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Help;