// File: assets/runClubDataIndex.js

import { SF_RUN_CLUB_DATA } from './clubData/sanfrancisco/sfrunClubData.js';
import { EAST_BAY_RUN_CLUB_DATA } from './clubData/eastbay/eastBayRunClubData.js';
import { SANTA_BARBARA_RUN_CLUB_DATA } from './clubData/santabarbara/sbrunClubData.js';
import { SACRAMENTO_RUN_CLUB_DATA } from './clubData/sacramento/sacrunclubdata.js';
import { SAN_DIEGO_CLUB_DATA } from './clubData/sandiego/sandiegoClubData.js';
import { SAN_JOSE_CLUB_DATA } from './clubData/sanjose/sjClubData.js';
import { SONOMA_RUN_CLUB_DATA } from './clubData/sonoma/sonomaClubData.js';
import { NAPA_RUN_CLUB_DATA } from './clubData/napa/napaClubData.js';
import { MARIN_RUN_CLUB_DATA } from './clubData/marin/marinClubData.js';
import { PALO_ALTO_CLUB_DATA } from './clubData/paloalto/paloaltoClubData.js';

// Combine all run club data into a single array
export const ALL_RUN_CLUB_DATA = [
  ...SF_RUN_CLUB_DATA,
  ...EAST_BAY_RUN_CLUB_DATA,
  ...SANTA_BARBARA_RUN_CLUB_DATA,
  ...SACRAMENTO_RUN_CLUB_DATA,
  ...SAN_DIEGO_CLUB_DATA,
  ...SAN_JOSE_CLUB_DATA,
  ...SONOMA_RUN_CLUB_DATA,
  ...NAPA_RUN_CLUB_DATA,
  ...MARIN_RUN_CLUB_DATA,
  ...PALO_ALTO_CLUB_DATA
];

// If you want to keep the original CLUBDATA name for backwards compatibility
export const CLUBDATA = ALL_RUN_CLUB_DATA;

// Generate a list of unique cities
export const CITIES = [...new Set(ALL_RUN_CLUB_DATA.map(club => club.city))];

