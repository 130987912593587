import React from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Grid, Box } from '@mui/material';
import CardComponent from '../Components/CardComponent';
import { ALL_RUN_CLUB_DATA } from '../assets/runClubDataIndex';
import BackButton from '../Components/backbutton/BackButton';

export default function RegionDetailPage() {
  const { region } = useParams();
  
  // Convert URL format to display format (e.g., "sanfrancisco" -> "San Francisco")
  const displayRegion = region
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  // Filter clubs for this region
  const regionClubs = ALL_RUN_CLUB_DATA.filter(club => 
    club.city.toLowerCase().replace(/\s+/g, '-') === region
  );

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <BackButton />
      <Box mb={6}>
        <Typography variant="h1" component="h1" gutterBottom sx={{ 
          fontSize: { xs: '2rem', md: '3rem' },
          fontWeight: 700,
          mb: 2
        }}>
          Running Clubs in {displayRegion}
        </Typography>
        <Typography variant="h2" component="h2" sx={{ 
          fontSize: { xs: '1.25rem', md: '1.5rem' },
          color: 'text.secondary',
          mb: 4
        }}>
          {regionClubs.length} Running Club{regionClubs.length !== 1 ? 's' : ''} in {displayRegion}
        </Typography>

        <Grid container spacing={3}>
          {regionClubs.map((club) => (
            <Grid item xs={12} sm={6} md={4} key={club.id}>
              <CardComponent 
                data={club}
                useCategory={false}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
} 