export const SACRAMENTO_RUN_CLUB_DATA = [
  
    {
      id: "captappers",
      title: "Cap Tappers Run Club",
      subtitle: "",
      isVerified: true,
      description: "Capitol Beer and Taproom's biggest fans",
      img: require('../../images/sacramento/captappers.png'),
      categories: ["Post-Run Socials", "Free"],
      day: ["Monday"],
      daysOfWeek: ["Monday"],
      city: "Sacramento", // Added city
    },

    {
        id: "fleetfeetsacramento",
        title: "Fleet Feet Run Crew",
        subtitle: "Sacramento",
        isVerified: true,
        description: "Run or walk with Sacramento's friendliest running club.",
        img: require('../../images/sacramento/fleetfeet.png'),
        categories: ["Social", "Free", "Evening"],
        day: ["Tuesday"],
        daysOfWeek: ["Tuesday"],
        city: "Sacramento",
      },

      {
        id: "sloppy-moose-running-club",
        title: "Sloppy Moose Running Club",
        subtitle: "Sacramento, CA",
        description: "Casual Thursday night runs followed by beers at Bikedog Brewing.",
        img: require('../../images/sacramento/sloppy.png'), // Add manually
        categories: ["Social", "Free", "Evening"],
        day: ["Thursday"],
        daysOfWeek: ["Thursday"],
        city: "Sacramento",
      }
      
]