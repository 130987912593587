import React, { useEffect } from 'react';
import './AboutUs.css';
import BackButton from './backbutton/BackButton';

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-container">
      <header className="about-header">
        <BackButton/>
        <h1 className="about-title">About clubsta</h1>
        <p className="about-subtitle">Find, join and attend a running club</p>
      </header>

      <main className="about-content">
        <section className="about-section">
          <h3 className="section-title">Our goal</h3>
          <div className="section-content">
            <p>Welcome to clubsta 👋 – our aim is to help people find the best running groups located near them. Our hope is that by helping people discover running clubs we will help them create new connections that will last a lifetime.</p>
            <p>Our aim is to build the world's best and most extensive run club directory. 🌎</p>
          </div>
        </section>

        <section className="about-section">
          <h3 className="section-title">Submit a club ℹ</h3>
          <div className="section-content">
            <p>If we've missed a club or you'd like to add your own club to the directory, please send us a message!</p>
          </div>
        </section>

        <section className="about-section">
          <h3 className="section-title">Get in touch 📧</h3>
          <div className="section-content">
            <p>If something looks out of date or you have questions or feedback, please contact us at luke.furnell@clubsta.co.</p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AboutUs;