import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CLUBDATA } from '../assets/runClubDataIndex';
import MediaCard from './CardComponent';
import BackButton from './backbutton/BackButton';
import './CategoryDetail.css';

const CategoryDetail = () => {
  console.log("CategoryDetail component rendered");
  
  const params = useParams();
  console.log("All params:", params);

  const { city, categoryName } = params;
  console.log("Extracted params:", { city, categoryName });

  const navigate = useNavigate();

  useEffect(() => {
    console.log("CategoryDetail mounted");
  }, []);

  // Format display text
  const displayCity = city.replace(/-/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  const displayCategory = categoryName.replace(/-/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
  console.log("Formatted values:", { displayCity, displayCategory });

  // Filter clubs based on both city and category
  const filteredClubs = CLUBDATA.filter(club => {
    // Debug logs
    console.log("Checking club:", club.title);
    console.log("Club city:", club.city, "Looking for:", displayCity);
    console.log("Club categories:", club.categories);
    console.log("Looking for category:", displayCategory);
    
    return (
      club.city === displayCity && 
      club.categories && 
      club.categories.includes(displayCategory)  // Exact match with displayCategory
    );
  });

  console.log("Final filtered clubs:", filteredClubs);

  // Handle click event on a club to navigate to its profile page
  const handleClubClick = (club) => {
    const formattedCity = city.toLowerCase().replace(/\s+/g, '-');
    const formattedCategory = categoryName.toLowerCase();
    
    navigate(`/club/${formattedCity}/${formattedCategory}/${club.id}`);
  };

  return (
    <div className="category-detail">
      <div className="category-header">
        <BackButton />
        <h1>{displayCategory} Running Clubs in {displayCity}</h1>
      </div>
      <div className="card-section grid-layout">
        {filteredClubs.length > 0 ? (
          filteredClubs.map((club) => (
            <MediaCard 
              key={club.id} 
              data={club} 
              categoryContext={displayCategory}
              useCategory={true}
              handleClubClick={() => handleClubClick(club)}
            />
          ))
        ) : (
          <div className="no-results">
            <p>No clubs found for {displayCategory} in {displayCity}.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryDetail;
