import React from 'react';
import { ALL_RUN_CLUB_DATA } from '../assets/runClubDataIndex';
import './Host.css';

const Host = ({ clubId }) => {
  const club = ALL_RUN_CLUB_DATA.find(club => club.id === clubId);
  
  if (!club || !club.host) {
    return null; // Don't render anything if there's no host information
  }

  return (
    <div className="host-section">
      <h2>Host</h2>
      <div className="host-info">
        {club.host.image && (
          <img src={club.host.image} alt={club.host.name} className="host-image" />
        )}
        <div className="host-details">
          <h3>{club.host.name}</h3>
        </div>
      </div>
    </div>
  );
};

export default Host;