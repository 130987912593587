

export const SONOMA_RUN_CLUB_DATA = [
  
    {
        id: "sonoma-valley-runners",
        title: "Sonoma Valley Runners",
        subtitle: "Sonoma",
        description: "A running club that blends fitness with post-run fun—think ice baths, saunas, and craft beer.",
        img: require('../../images/sonoma/sonoma.png'), // Add manually
        categories: ["Social", "Free", "Morning"],
        day: ["Sunday"],
        daysOfWeek: ["Sunday"],
        city: "Sonoma", 
      },

    {
        id: "lightning-run-club",
        title: "Lightning Run Club",
        subtitle: "Sonoma",
        description: "A supportive community offering track workouts and social runs for all paces.",
        img: require('../../images/sonoma/lightning.png'), // Add manually
        categories: ["Social", "Free", "Morning", "Evening"],
        day: ["Tuesday", "Sunday"],
        daysOfWeek: ["Tuesday", "Sunday"],
        city: "Sonoma",
      }
      
      


]