export const SF_RUN_CLUB_DATA = [
  
  {
    id: "fat-boys-run-club",
    title: "Fat Boys Run Club",
    subtitle: "Kezar Stadium, San Francisco",
    isVerified: true,
    description: "San Francisco's most unhinged run club.",
    img: require('../../images/sanfrancisco/fatboysrunclub.png'),
    videoSrc: require('../../fatboys.mp4'),
    categories: ["Post-Run Socials", "Free"],
    day: ["Tuesday", "Saturday"],
    daysOfWeek: ["Tuesday", "Saturday"],
    city: "San Francisco", // Added city
  },
  
  {
    id: "marina-run-club",
    title: "Marina Run Club",
    description: "Community of runners in the marina.",
    subtitle: "Marina, San Francisco",
    img: require('../../images/sanfrancisco/marina.png'),
    isVerified: true,
    categories: ["Social", "Morning", "Evening", "Training", "Free"],
    day: ["Tuesday", "Thursday", "Saturday"],
    daysOfWeek: ["Tuesday", "Thursday", "Saturday"],
    videoSrc: require('../../marina2.mp4'),
    city: "San Francisco", // Added city
  },

  // {
  //   id: "bay-area-run-club",
  //   title: "Bay Area Run Club (BARC)",
  //   subtitle: "Bay Area, San Francisco",
  //   description: "A running club for all backgrounds and ages.",
  //   img: require('../assets/bayareaclub.png'),
  //   day: ["Tuesday", "Saturday"],
  //   categories: ["Social", "Morning", "Free"],
  //   videoSrc: require('../assets/barc.mp4'),
  //   city: "San Francisco", // Added city
  // },

  {
    id: "midnight-runners",
    title: "Midnight Runners",
    subtitle: "SoMa, San Francisco",
    description: "Running with a bootcamp and some dancing.",
    videoSrc: require('../../midnightrunners.mp4'),
    isVerified: true,
    img: require('../../images/sanfrancisco/midnight.png'),
    categories: ["Social", "Evening", "Free"],
    day: ["Wednesday"],
    daysOfWeek: ["Wednesday"],
    city: "San Francisco", // Added city
  },

  {
    id: "friday-run-club",
    title: "Friday Run Club",
    subtitle: "North Beach, San Francisco",
    description: "Potentially the slowest run club in SF.",
    isVerified: true,
    img: require('../../images/sanfrancisco/bodega.jpg'),
    videoSrc: require('../../frc.mp4'),
    day: ["Friday"],
    daysOfWeek: ["Friday"],
    categories: ["Social", "Morning", "Free"],
    city: "San Francisco", // Added city
  },

  {
    id: "north-beach",
    title: "North Beach Run Club",
    subtitle: "North Beach, San Francisco",
    description: "Free high fives and good vibes.",
    img: require('../../images/sanfrancisco/northbeach.png'),
    videoSrc: require('../../northbeach.mp4'),
    day: ["Wednesday"],
    daysOfWeek: ["Wednesday"],
    categories: ["Social", "Morning", "Free"],
    city: "San Francisco", // Added city
  },

  {
    id: "sf-coffee-club",
    title: "SF Coffee Club",
    subtitle: "San Francisco",
    description: "Second SF coffee club in the US.",
    img: require('../../images/sanfrancisco/sfcoffeeclub.png'),
    day: ["Tuesday"],
    daysOfWeek: ["Tuesday"],
    categories: ["Social", "Morning", "Free"],
    city: "San Francisco", // Added city
  },

  {
    id: "impala-racing",
    title: "Impala Racing Team",
    subtitle: "Bay Area, San Francisco",
    description: "Women's elite development team in SF.",
    isVerified: true,
    img: require('../../images/sanfrancisco/impala.png'),
    categories: ["Competitive", "Female-only"],
    city: "San Francisco", // Added city
  },

   {
    id: "track-and-feels",
    title: "Track & Feels",
    subtitle: "Golden Gate Park, San Francisco",
    description: "Slow running and slow jams.",
    isVerified: true,
    img: require('../../images/sanfrancisco/trackandfeels.png'),
    day: ["Sunday"],
    daysOfWeek: ["Sunday"],
    videoSrc: require('../../feels.mp4'),
    categories: ["Social", "Morning", "Free"],
    city: "San Francisco", // Added city
  },

  {
    id: "run-club-sf",
    title: "Run Club SF",
    subtitle: "San Francisco",
    description: "A social club with a running problem.",
    // isVerified: true,
    img: require('../../images/sanfrancisco/runclubsf.png'),
    day: ["Tuesday", "Thursday", "Saturday"],
    daysOfWeek: ["Tuesday", "Thursday", "Saturday"],
    videoSrc: require('../../runclubsf.mp4'),
    categories: ["Social", "Morning", "Evening", "Free"],
    city: "San Francisco", // Added city
  },

  // {
  //   id: "hashhouse-harriers",
  //   title: "Hash House Harriers",
  //   subtitle: "Bay Area, San Francisco",
  //   description: "A drinking club with a running problem.",
  //   img: "https://images.unsplash.com/photo-1449358070958-884ac9579399?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  //   categories: ["Drinking", "Evening", "Paid"],
  //   day: ["Monday", "Wednesday", "Thursday", "Saturday"],
  //   city: "San Francisco", // Added city
  // },
  
  {
    id: "golden-gate-running-club",
    title: "Golden Gate Running Club",
    subtitle: "Presidio, San Francisco",
    description: "A friendly running club for all levels.",
    img: require('../../images/sanfrancisco/goldengate.png'),
    categories: ["Evening"],
    day: ["Wednesday", "Sunday"],
    daysOfWeek: ["Wednesday", "Sunday"],
    city: "San Francisco", // Added city
  },

  {
    id: "sf-road-runners-club",
    title: "SF Road Runners Club",
    subtitle: "Golden Gate Park, San Francisco",
    description: "San Francisco's largest running club.",
    isVerified: true,
    img: require('../../images/sanfrancisco/sfroadrunners.png'),
    videoSrc: require('../../sfroadrunners.mp4'),
    categories: ["Competitve", "Post-Run Socials", "Paid"],
    day: ["Tuesday", "Wednesday", "Thursday", "Saturday", "Sunday"],
    daysOfWeek: ["Tuesday", "Wednesday", "Thursday", "Saturday", "Sunday"],
    city: "San Francisco", // Added city
  },

  // {
  //   id: "sf-front-runners",
  //   title: "SF FrontRunners",
  //   subtitle: "San Francisco",
  //   description: "A worldwide network of LGBTQIA+ running clubs.",
  //   isVerified: true,
  //   img: require('../assets/frontrunners.png'),
  //   categories: ["Evening", "Paid"],
  //   day: ["Tuesday", "Wednesday", "Thursday", "Saturday", "Sunday"],
  //   city: "San Francisco", // Added city
  // },
  // {
  //   id: "run-club-sf",
  //   title: "Run Club SF",
  //   subtitle: "Kezar Stadium, San Francisco",
  //   description: "A social club with a running problem.",
  //   img: require('../assets/runclubsf.png'),
  //   categories: ["Training", "Paid"],
  //   day: ["Tuesday", "Thursday", "Saturday"],
  //   city: "San Francisco", // Added city
  // },

  {
    id: "noe-valley-run-club",
    title: "Noe Valley Run Club",
    subtitle: "Noe Valley, San Francisco",
    description: "A 2 year old running club with coffee and treats.",
    img: require('../../images/sanfrancisco/noevalley.png'),
    videoSrc: require('../../noe.mp4'),
    categories: ["Free", "Social", "Morning"],
    day: ["Friday"],
    daysOfWeek: ["Friday"],
    city: "San Francisco", // Added city
  },

  {
    id: "fog-city-run-club",
    title: "Fog City Run Club",
    subtitle: "Cow Hollow, San Francisco",
    description: "A weekly running race in San Francisco.",
    img: require('../../images/sanfrancisco/fogcity.png'),
    categories: ["Competitive", "Paid", "Evening"],
    day: ["Wednesday"],
    daysOfWeek: ["Wednesday"],
    city: "San Francisco", // Added city
  },
  {
    id: "unseen-run-club",
    title: "Unseen Run Club",
    subtitle: "Mission, San Francisco",
    description: "The bay area's best kept secret.",
    img: require('../../images/sanfrancisco/unseenrunclub.png'),
    videoSrc: require('../../unseen.mp4'),
    categories: ["Social", "Free"],
    day: ["Monday"],
    daysOfWeek: ["Monday"],
    city: "San Francisco", // Added city
  },

  {
    id: "bv-run-club",
    title: "BV Run Club",
    subtitle: "San Francisco",
    isVerified: true,
    description: "Focused on physical, mental and social fitness.",
    img: require('../../images/sanfrancisco/bvrunclub.png'),
    videoSrc: require('../../bvrun.mp4'),
    categories: ["Morning", "Evening", "Free"],
    day: ["Wednesday", "Saturday"],
    daysOfWeek: ["Wednesday", "Saturday"],
    city: "San Francisco", // Added city
  },

  {
    id: "mission-funrun",
    title: "SF Mission Fun Runs",
    subtitle: "Mission, San Francisco",
    description: "Fun runs from a new spot each week.",
    isVerified: true,
    img: require('../../images/sanfrancisco/missionfunruns.png'),
    categories: ["Evening", "Social", "Free"],
    day: ["Monday"],
    daysOfWeek: ["Monday"],
    city: "San Francisco", // Added city
  },

  {
    id: "runners-mind",
    title: "A Runner's Mind Run",
    subtitle: "Presidio Heights, San Francisco",
    description: "A running club by the folks at A Runner's Mind.",
    isVerified: true,
    img: require('../../images/sanfrancisco/runnersmind.png'),
    categories: ["Social", "Evening", "Free"],
    day: ["Tuesday", "Thursday"],
    daysOfWeek: ["Tuesday", "Thursday"],
    city: "San Francisco", // Added city
  },

  {
    id: "dolphin-southend",
    title: "DSE Run Club",
    subtitle: "San Francisco",
    description: "Weekly running races in SF for $10.",
    img: require('../../images/sanfrancisco/dserunners.png'),
    categories: ["Competitive", "Paid"],
    city: "San Francisco", // Added city
  },
  // {
  //   id: "sf-trackandfield",
  //   title: "San Francisco Track and Field",
  //   subtitle: "San Francisco",
  //   description: "Weekly running practice for all.",
  //   img: require('../assets/sftrackandfield.png'),
  //   categories: ["Training","Paid"],
  //   day: ["Sunday"],
  //   city: "San Francisco", // Added city
  // },

  {
    id: "founders-running-club",
    title: "Founders Running Club",
    subtitle: "Presidio, San Francisco",
    description: "A club for founders and startup enthusiasts.",
    img: require('../../images/sanfrancisco/foundersrunclub.png'),
    categories: ["Morning", "Free"],
    day: ["Saturday"],
    daysOfWeek: ["Saturday"],
    city: "San Francisco", // Added city
  },
  {
    id: "pamakid-running",
    title: "Pamakid Runners",
    subtitle: "Kezar Stadium, San Francisco",
    description: "A club known for its charitable giving.",
    img: require('../../images/sanfrancisco/pama.png'),
    isVerified: true,
    categories: ["Competitive"],
    day: ["Wednesday"],
    daysOfWeek: ["Wednesday"],
    city: "San Francisco", // Added city
  },
  {
    id: "nth-degree",
    title: "Nth Degree",
    subtitle: "San Francisco",
    description: "Runners without limits.",
    img: require('../../images/sanfrancisco/nthdegree.png'),
    videoSrc: require('../../nthdegree.mp4'),
    isVerified: true,
    categories: ["Competitve"],
    day: ["Tuesday", "Thursday", "Saturday"],
    daysOfWeek: ["Tuesday", "Thursday", "Saturday"],
    city: "San Francisco", // Added city
  },
  // {
  //   id: "urban-trail",
  //   title: "SF Urban Trail Runners",
  //   subtitle: "Inner Sunset, San Francisco",
  //   description: "Discover trails and friends in SF.",
  //   img: require('../assets/urbantrail.png'),
  //   categories: ["Free"],
  //   day: ["Tuesday", "Thursday", "Saturday"],
  //   city: "San Francisco", // Added city
  // },
  {
    id: "november-project",
    title: "November Project",
    subtitle: "San Francisco",
    description: "Lowering the intimidation of fitness.",
    isVerified: true,
    img: require('../../images/sanfrancisco/november.png'),
    videoSrc: require('../../novemberproject.mp4'),
    day: ["Friday", "Tuesday", "Wednesday"],
    categories: ["Free", "Morning"],
    daysOfWeek: ["Friday"],
    city: "San Francisco", // Added city
  },




];
