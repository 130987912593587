export const NAPA_RUN_CLUB_DATA = [

    {
        id: "rebel-run-club-napa",
        title: "Rebel Run Club Napa",
        subtitle: "Napa, CA",
        description: "A social running club for young wellness enthusiasts who love to sweat and connect.",
        img: require('../../images/napa/rebel.png'), // Add manually
        categories: ["Social", "Morning", "Free"],
        day: ["Saturday"],
        daysOfWeek: ["Saturday"],
        city: "Napa",
      }
      

 

]