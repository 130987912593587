import rebel from '../../images/napa/rebel.png';

export const NAPA_CLUB_PROFILES = {

    "rebel-run-club-napa": {
  id: "rebel-run-club-napa",
  title: "Rebel Run Club Napa",
  images: [rebel], // Add images manually
  isVerified: true,
  badges: ["social", "morning", "free"],
  whatToExpect: "Rebel Run Club Napa is a community for young wellness enthusiasts who love to sweat, socialize, and share miles together. Expect a friendly and energetic group that meets at rotating locations for a morning run, followed by a coffee social at Starbucks.",
  howToJoin: "Just show up at the meeting point—no sign-up required. Bring your energy and enjoy a fun, social run!",
  description:
    "Rebel Run Club Napa brings together runners looking to connect, challenge themselves, and explore Napa’s beautiful trails. Whether you're in it for the workout or the post-run coffee, this club is all about good vibes and great company.",
  subtitle: "Napa, CA",
  whyWeLikeIt:
    "Rebel Run Club is perfect for those who love a mix of fitness and fun. Their focus on community and social connection makes every run feel like an event, not just a workout.",
  socialInfo: [
    "Post-run Starbucks social",
    "All paces welcome",
    "Scenic Napa routes",
    "Social and community-driven"
  ],
  contactInfo: [
    {
      name: "Instagram",
      url: "https://www.instagram.com/rebelrunclubnapa/",
      platform: "instagram",
    },
    {
      name: "Strava",
      url: "https://www.strava.com/clubs/rebelrunclubnapa",
      platform: "strava",
    },
  ],
  meetPoint: {
    details: ["Meet at the NORTH parking lot of Alston Park"],
    link: {
      text: "Alston Park, Napa",
      url: "https://maps.app.goo.gl/example", // Replace with actual link if available
    },
  },
  runDays: [
    {
      day: "Saturday",
      times: ["9:00 AM"],
      distance: "2 or 3 miles",
      location: "Location varies (details on Strava)",
      googleMapLink: ""
    }
  ],
  joinLink: "",
  instagramEmbed: `<blockquote class="instagram-media" data-instgrm-permalink="https://www.instagram.com/rebelrunclubnapa/" data-instgrm-version="14" style=" background:#FFF; border-radius:px; box-shadow:0 0 1px 0 rgba(0,0,0,0.5),0 1px 10px 0 rgba(0,0,0,0.15); margin: 1px; max-width:540px; min-width:326px; padding:0; width:100%; width:-webkit-calc(100% - 2px); width:calc(100% - 2px);"><div style="padding:16px; border:0"> <a href="https://www.instagram.com/rebelrunclubnapa/" style=" background:#FFFFFF; line-height:0; padding:0 0; text-align:center; text-decoration:none; width:100%;" target="_blank"> <div style=" display: flex; flex-direction: row; align-items: center;"> <div style="background-color: #F4F4F4; border-radius: 50%; flex-grow: 0; height: 40px; margin-right: 14px; width: 40px;"></div> <div style="display: flex; flex-direction: column; flex-grow: 1; justify-content: center;"> <div style=" background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; margin-bottom: 6px; width: 100px;"></div> <div style=" background-color: #F4F4F4; border-radius: 4px; flex-grow: 0; height: 14px; width: 60px;"></div></div></div><div style="padding: 19% 0;"></div> <div style="display:block; height:50px; margin:0 auto 12px; width:50px;"></div></a></div></blockquote>`,
}




}