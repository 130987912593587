export const SAN_JOSE_CLUB_DATA = [
    {
      id: "runfreerc",
      title: "Run Free Run Club",
      subtitle: "Explore San Jose with RFRC",
      isVerified: true,
      description: "Enjoy the freedom of running with RFRC!",
      img: require('../../images/sanjose/rf.png'),
      categories: ["Social", "Free", "Morning"],
      day: ["Saturday", "Friday"],
      daysOfWeek: ["Saturday"],
      city: "San Jose",
    }
]
